// type
import { Home3, HomeTrendUp } from 'iconsax-react';
// icons
const icons = {
    navigation: Home3,
    service: HomeTrendUp
};

// ==============================|| MENU ITEMS - service ||============================== //

const service = {
    id: 'group-service',
    title: 'Service',
    icon: icons.navigation,
    type: 'group',
    children: [
        {
            id: 'all-service',
            title: 'All Services',
            type: 'item',
            url: '/service/all-service',
            icon: icons.service,
            breadcrumbs: true
        },
        {
            id: 'create-service',
            title: 'Create Service',
            type: 'item',
            url: '/service/add-service',
            icon: icons.service,
            breadcrumbs: true
        },
        {
            id: 'assign-service',
            title: 'Assign Service',
            type: 'item',
            url: '/service/assign-service',
            icon: icons.service,
            breadcrumbs: true
        }
    ]
};

export default service;
