// material-ui

import MainCard from 'components/MainCard';
import { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import { dispatch } from 'store/index';
import { AssignAllCurrency, GetAllCurrency } from 'store/action/index';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import MultipleSelect from 'components/inputs/MultipleSelect';
import ThemeInput from 'components/inputs/ThemeInput'; // Assuming you have this component

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AssignCurrency = ({ getAllCurrency }) => {
    const location = useLocation();
    console.log('location.state', location.state);
    const [senderCurrencies, setSenderCurrencies] = useState(location.state !== null ? location.state.service : null);
    const [receiveCurrencies, setReceiveCurrencies] = useState(location.state !== null ? location.state.receiver_services.map(service => service.service) : []);
    const [loading, setLoading] = useState(true);
    const [rates, setRates] = useState({});

    useEffect(() => {
        dispatch(GetAllCurrency({ setLoading: setLoading }));
    }, []);

    useEffect(() => {
        if (location.state && location.state.receiver_services) {
            const initialRates = {};
            location.state.receiver_services.forEach(service => {
                initialRates[service.service] = {
                    sender_rate: service.pivot.sender_rate,
                    receiver_rate: service.pivot.reciver_rate
                };
            });
            setRates(initialRates);
        }
    }, [location.state]);

    const getServiceIds = (shortCodes) => {
        return getAllCurrency
            .filter(service => shortCodes.includes(service.service))
            .map(service => service.id);
    };

    const handleSubmit = () => {
        if (senderCurrencies !== null || receiveCurrencies.length !== 0) {
            const payload = {
                sender_service: getServiceIds([senderCurrencies])[0],
                receiver_service: receiveCurrencies.map(service => ({
                    receiver_service_id: getServiceIds([service])[0],
                    sender_rate: rates[service]?.sender_rate || '',
                    receiver_rate: rates[service]?.receiver_rate || ''
                })),
            };

            console.log(payload); // To log the payload in the desired format

            dispatch(AssignAllCurrency({
                payload, setLoading: setLoading,
            }));
        } else {
            toast.error("Required Fields are empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                bodyClassName: 'toastStyle',
            });
        }
    };

    const handleRateChange = (service, rateType, value) => {
        setRates(prevRates => ({
            ...prevRates,
            [service]: {
                ...prevRates[service],
                [rateType]: value
            }
        }));
    };

    const handleSenderCurrencyChange = (e) => {
        setSenderCurrencies(e);
        setReceiveCurrencies([]);
        setRates({});
        const dataSelectOne = getAllCurrency
            .filter(service => [e].includes(service.service))
            .map(service => service);
        const newReceiverCurrencies = dataSelectOne[0]?.receiver_services.map(service => service.service) || [];
        setReceiveCurrencies(newReceiverCurrencies);

        // Set rates for new receiver currencies
        const newRates = {};
        newReceiverCurrencies.forEach(service => {
            const serviceData = dataSelectOne[0].receiver_services.find(s => s.service === service);
            newRates[service] = {
                sender_rate: serviceData.pivot.sender_rate,
                receiver_rate: serviceData.pivot.reciver_rate
            };
        });
        setRates(newRates);
    };

    return (
        <MainCard>
            <Spin spinning={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className='row'>
                            <div className='col-md-4 my-2'>
                                <MultipleSelect
                                    name="Sender Currencies"
                                    placeholder="Select Sender Currencies"
                                    id="names"
                                    onChange={handleSenderCurrencyChange}
                                    options={getAllCurrency}
                                    defaultValue={senderCurrencies}
                                />
                            </div>
                            <div className='col-md-4 my-2'>
                                <MultipleSelect
                                    mode="multiple"
                                    name="Receiver Currencies"
                                    placeholder="Select Receiver Currencies"
                                    id="names"
                                    allowClear
                                    disabled={senderCurrencies === null}
                                    onChange={(e) => setReceiveCurrencies(e)}
                                    options={getAllCurrency}
                                    defaultValue={receiveCurrencies}
                                />
                            </div>
                        </div>
                        
                        {receiveCurrencies.map((currency, index) => {
                            const service = getAllCurrency.find(service => service.service === currency);
                            return (
                                <div key={index} className='row'>
                                    <div className='col-md-4 my-3'>
                                    <div className="mb-4">
                        <span
                          className="my-2"
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                          }}
                        >
                          {index + 1}.{" "}
                          <img
                            alt={service.short_code}
                            src={`https://epaisachanger.com/api/public/${service.logo}`}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "15px",
                              marginLeft: 10,
                              marginBottom: 5,
                              marginRight: 5,
                            }}
                          />{" "}
                          {service.service} ({service.short_code})
                        </span>
                        <p className="mx-5" style={{ fontSize:15, margin: 6 }}>
                          <b>Min: </b>
                          {service.min ? service.min : 0}
                        </p>
                        <p className="mx-5" style={{ fontSize: 15, margin: 6 }}>
                          <b>Max: </b>
                          {service.max ? service.max : 0}
                        </p>
                        <p className="mx-5" style={{ fontSize: 15, margin: 6 }}>
                          <b>Reserve: </b>
                          {service.reserve ? service.reserve : 0}
                        </p>
                        <p className="mx-5" style={{ fontSize: 15, margin: 6 }}>
                          <b>Fee: </b>
                          {service.fee ? service.fee : 0}
                        </p>
                      </div>

                                    </div>
                                    <div className='col-md-4 my-2'>
                                        <ThemeInput
                                            id={`sender_rate_${index}`}
                                            value={rates[currency]?.sender_rate || ''}
                                            onChange={(e) => handleRateChange(currency, 'sender_rate', e.target.value)}
                                            type="number"
                                            name="Sender Rate"
                                            placeholder="Eg. 50"
                                        />
                                    </div>
                                    <div className='col-md-4 my-2'>
                                        <ThemeInput
                                            id={`receiver_rate_${index}`}
                                            value={rates[currency]?.receiver_rate || ''}
                                            onChange={(e) => handleRateChange(currency, 'receiver_rate', e.target.value)}
                                            type="number"
                                            name="Receiver Rate"
                                            placeholder="Eg. 50"
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        
                        <div className='my-3' style={{ textAlign: "end" }}>
                            <Button
                                onClick={handleSubmit}
                                disableElevation
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Assign
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Spin>
        </MainCard>
    );
};

const mapStateToProps = (state) => {
    return {
        getAllCurrency: state?.GetAllCurrency?.getAllCurrency,
    };
};

export default connect(mapStateToProps)(AssignCurrency);
