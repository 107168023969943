// project-imports
import service from './currency';
import dashboard from './dashboard';
import transactions from './transactions';
import users from './users';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    service,
    transactions,
    users
  ]
};

export default menuItems;