import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AssignCurrency from "pages/currency/AssignCurrency";
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
// render - data display components
const AddCurrency = Loadable(
  lazy(() => import("pages/currency/AddCurrency"))
);
const AllUsersActual = Loadable(
  lazy(() => import("pages/users/AllUsers"))
);
const AllTransactions = Loadable(
  lazy(() => import("pages/transaction/allTransactions"))
);

const AllCurrency = Loadable(lazy(() => import("pages/currency/AllCurrency")));




const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children:
        [
          {
            path: "/",
            element: <DashboardDefault />,
          },
          {
            path: "dashboard",
            element: <DashboardDefault />,
          },
          {
            path: "*",
            element: <DashboardDefault />,
          },
        ],
    },
 {
        path: "users",
        element: <MainLayout />,
        children: [
{
              path: "all-users",
              element: <AllUsersActual />,
            }
        ],
      },
 {
        path: "transactions",
        element: <MainLayout />,
        children: [
{
              path: "all-transactions",
              element: <AllTransactions />,
            }
        ],
      },
 {
        path: "service",
        element: <MainLayout />,
        children: [
{
              path: "all-service",
              element: <AllCurrency />,
            }
        ],
      },
      {
        path: "service",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "add-service",
              element: <AddCurrency />,
            }
            : null,
        ],
      },
      {
        path: "service",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "assign-service",
              element: <AssignCurrency />,
            }
            : null,
        ],
      }
  ],
};

export default MainRoutes;
