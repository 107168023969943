import React from 'react';
import { Grid, InputLabel, Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { Select } from 'antd';

const { Option } = Select;

const MultipleSelect = (props) => {
  let { mode, name, placeholder, id, allowClear, errorText, error, disabled, options, defaultValue, onChange } = props;

  return (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <InputLabel htmlFor={id}>{name}</InputLabel>
        <Select
          mode={mode}
          id={id}
          allowClear={allowClear}
          placeholder={placeholder}
          value={defaultValue}
          disabled={disabled}
          onChange={onChange}
          style={{
            flex: 1,
          }}
        >
          {options.map((option) => (
            <Option key={option.id} value={option.service}>
              {option.logo && <img
            alt={option.short_code}
            src={`https://epaisachanger.com/api/public/${option.logo}`}
            style={{ width: "20px", marginRight: '10px', height: "20px", borderRadius: "15px" }}
          />}{option.service}
            </Option>
          ))}
        </Select>
        {error ? (
          <FormHelperText style={{ margin: '0px 3%', color: 'red', fontSize: 10 }}>{errorText}</FormHelperText>
        ) : (
          <div></div> // Render nothing when error is false
        )}
      </Stack>
    </Grid>
  );
};

MultipleSelect.defaultProps = {
  errorText: '',
  error: false,
  options: [],
  defaultValue: [],
};

export default MultipleSelect;
