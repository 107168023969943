import {
    VIEW_USERS
} from "../constant/ActionType";

export default function AllUsers(state = {
    allUsers: [],
}, action) {
    switch (action.type) {
        case VIEW_USERS:
            return {
                ...state,
                allUsers: action.data?.data?.users,
            };

        default:
    }

    return state;
}