// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import AllUsers from "./edituser";

import AllCompaniesReducers from "./allcompany";

import GetAllCurrency from "./users/getAllCurrency";
import AllTransactions from "./AllTransactions";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({

  GetAllCurrency,
  menu,
  AllUsers,
  AllTransactions,
  AllCompaniesReducers,
});

export default reducers;
