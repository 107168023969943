import {
    VIEW_TRANSACTIONS
} from "../constant/ActionType";

export default function AllTransactions(state = {
    AllTransactions: [],
}, action) {
    switch (action.type) {
        case VIEW_TRANSACTIONS:
            return {
                ...state,
                AllTransactions: action.data?.data,
            };

        default:
    }

    return state;
}